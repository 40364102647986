import './Footer.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Footer = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					m: 1,
					width: '100%',
					height: '80px',
				},
			}}
		>
			<Paper
				elevation={3}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
						m: 1,
						width: '100%',
						height: '100%',
					},
				}}
				style={{ marginBottom: '10px' }}
			>
				<footer
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						'& > :not(style)': {
							m: 1,
							width: '100%',
							height: '60px',
						},
					}}
				>
					<p>Copyright © {new Date().getFullYear()} Easy File</p>
				</footer>
			</Paper>
		</Box>
	);
};

export default Footer;
